<script lang="ts" setup>
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useQueries, useQuery } from '@tanstack/vue-query'
  import { storeToRefs } from 'pinia'

  import { dayjs, DTF, Nullable } from '@algorh/shared'
  import { AlgIcon, AlgNoData, AlgSpinner } from '@algorh/ui'

  import { CalendarCustomerAdvisersApiService } from '@/api/calendar'
  import { Satisfaction } from '@/api/interfaces/Planning'
  import { ScheduleSatisfaction } from '@/api/interfaces/Schedule'
  import { useAppStore } from '@/appStore'
  import { CanvasDonut } from '@/components'

  // Composables
  const { t } = useI18n()

  const appStore = useAppStore()

  // Data
  const { theme } = storeToRefs(appStore)

  // Refs
  const satisfactionColors = [theme.value.SATISFIED_SLOTS, theme.value.NOT_SATISFIED_SLOTS]

  const date = ref(dayjs())

  // Queries
  const staleTime = 5 * 60 * 1000 // 5 minutes

  const { data: globalSatisfaction, isFetching: isFetchingGlobalSatisfaction } = useQuery({
    queryKey: ['calendar', 'customer-advisers', 'statistics'],
    queryFn: CalendarCustomerAdvisersApiService.getGlobalSatisfaction,
    staleTime,
  })

  const scheduleSatisfactions = useQueries({
    queries: [
      {
        queryKey: ['calendar', 'customer-advisers', 'satisfaction', 'previous', date],
        queryFn: () => CalendarCustomerAdvisersApiService.getScheduleSatisfactions({
          year: date.value.subtract(1, 'month').isoWeekYear(),
          month: date.value.subtract(1, 'month').month() + 1,
        }),
        staleTime,
      },
      {
        queryKey: ['calendar', 'customer-advisers', 'satisfaction', 'current', date],
        queryFn: () => CalendarCustomerAdvisersApiService.getScheduleSatisfactions({
          year: date.value.isoWeekYear(),
          month: date.value.month() + 1,
        }),
        staleTime,
      },
      {
        queryKey: ['calendar', 'customer-advisers', 'satisfaction', 'next', date],
        queryFn: () => CalendarCustomerAdvisersApiService.getScheduleSatisfactions({
          year: date.value.add(1, 'month').isoWeekYear(),
          month: date.value.add(1, 'month').month() + 1,
        }),
        staleTime,
      },
    ],
    combine: (results) => {
      return {
        data: results.flatMap((result) => result.data?.data ?? []),
        isFetching: results.some((result) => result.isFetching),
      }
    },
  })

  // Computed
  const weeksInYear = computed(() => dayjs(date.value).startOf('month').isoWeeksInYear())

  const weeks = computed(() => {
    const startOfMonth = dayjs(date.value).startOf('month')
    const endOfMonth = dayjs(date.value).endOf('month')

    const startWeekOfMonth = startOfMonth.isoWeek() === weeksInYear.value ? 0 : startOfMonth.isoWeek()
    const endWeekOfMonth = endOfMonth.isoWeek() === 1 ? weeksInYear.value + 1 : endOfMonth.isoWeek()

    const weeksCount = endWeekOfMonth - startWeekOfMonth

    const weeks = Array.from({ length: weeksCount + 1 }, (_, i) => {
      const weekNumber = startWeekOfMonth + i

      return {
        number: weekNumber,
        satisfaction: (scheduleSatisfactions.value.data.find(
          (scheduleSatisfaction) => dayjs(scheduleSatisfaction.start_date).isoWeek() === weekNumber,
        ) ?? null) as Nullable<ScheduleSatisfaction>,
      }
    })

    return weeks
  })

  const globalSatisfactionSeries = computed(() => globalSatisfaction.value && getSatisfactionSeries(globalSatisfaction.value.data))
  const globalSatisfactionLabels = computed(() => globalSatisfaction.value && getSatisfactionLabels(globalSatisfaction.value.data))

  const scheduleSatisfactionSeries = computed(() => {
    if (!scheduleSatisfactions.value.data.length) {
      return [0, 0]
    }

    return scheduleSatisfactions.value.data.reduce(
      ([accSatisfied, accNotSatisfied], cur) => {
        const [curSatisfied, curNotSatisfied] = getSatisfactionSeries(cur)
        return [accSatisfied + curSatisfied, accNotSatisfied + curNotSatisfied]
      },
      [0, 0],
    )
  })

  const scheduleSatisfactionLabels = computed(() => {
    if (!scheduleSatisfactions.value.data.length) {
      return []
    }

    const satisfactions = scheduleSatisfactions.value.data.reduce(
      (acc, cur) => {
        const [satisfied_slots, not_satisfied_slots] = acc

        return [
          satisfied_slots + cur.satisfied_slots,
          not_satisfied_slots + cur.not_satisfied_slots,
        ]
      },
      [0, 0],
    )

    return [
      t('statistics.Satisfied ({value})', { value: satisfactions[0] }),
      t('statistics.Not satisfied ({value})', { value: satisfactions[1] }),
    ]
  })

  // Methods
  function getSatisfactionSeries(satisfaction: Nullable<Satisfaction | ScheduleSatisfaction>) {
    if (!satisfaction) {
      return [0, 0]
    }

    const { not_satisfied_slots, satisfied_slots } = satisfaction

    const total = not_satisfied_slots + satisfied_slots

    if (total === 0) {
      return [0, 0]
    }

    return [
      Math.round((100 * satisfied_slots) / total),
      Math.round((100 * not_satisfied_slots) / total),
    ]
  }

  function getSatisfactionLabels(satisfaction: Nullable<Satisfaction | ScheduleSatisfaction>) {
    if (!satisfaction) {
      return []
    }

    const { not_satisfied_slots, satisfied_slots } = satisfaction

    return [
      t('statistics.Satisfied ({value})', { value: satisfied_slots }),
      t('statistics.Not satisfied ({value})', { value: not_satisfied_slots }),
    ]
  }

  function getWeekNumber(weekNumber: number) {
    if (weekNumber === 0) {
      return weeksInYear.value
    }

    if (weekNumber === weeksInYear.value + 1) {
      return 1
    }

    return weekNumber
  }

  function getWeekSatisfiedLegend({ satisfied_slots, not_satisfied_slots }: ScheduleSatisfaction) {
    const color = theme.value.SATISFIED_SLOTS

    const total = satisfied_slots + not_satisfied_slots

    const count = total !== 0 ? Math.round((100 * satisfied_slots) / total) : 0

    const value = `<span class="satisfied" style="color: ${color};">
      ${t('units.{count}{symbol}', {
        count,
        symbol: '%',
    })}
    </span>`

    return t('statistics.{value} satisfied', { value })
  }

  function getWeekNotSatisfiedLegend({
    satisfied_slots,
    not_satisfied_slots,
  }: ScheduleSatisfaction) {
    const color = theme.value.NOT_SATISFIED_SLOTS

    const total = satisfied_slots + not_satisfied_slots

    const count = total !== 0 ? Math.round((100 * not_satisfied_slots) / total) : 0

    const value = `<span class="not-satisfied" style="color:${color};">
      ${t('units.{count}{symbol}', {
        count,
        symbol: '%',
    })}
    </span>`

    return t('statistics.{value} not satisfied', { value })
  }

  async function handlePreviousMonth() {
    date.value = dayjs(date.value).subtract(1, 'month')
  }

  async function handleNextMonth() {
    date.value = dayjs(date.value).add(1, 'month')
  }
</script>

<template>
  <div class="account-section">
    <h1 class="title">
      {{ t('statistics.Satisfied wishes') }}
    </h1>
    <div class="content-wrapper">
      <div class="column left">
        <h2 class="subtitle">
          {{
            t('statistics.Global statistics ({year})', {
              year: dayjs().format(DTF.YEAR)
            })
          }}
        </h2>
        <div class="statistics-wrapper main-statistics">
          <AlgSpinner
            v-if="isFetchingGlobalSatisfaction"
            size="l"
          />
          <template v-else>
            <CanvasDonut
              v-if="globalSatisfaction"
              direction="horizontal"
              :size="80"
              :colors="satisfactionColors"
              :series="globalSatisfactionSeries"
              :labels="globalSatisfactionLabels"
            />
            <AlgNoData
              v-else
              illustration="not-found"
              :title="t('common.No data')"
            />
          </template>
        </div>
      </div>
      <div class="column right">
        <h2 class="subtitle">
          {{ t('statistics.Month statistics') }}
        </h2>
        <div class="statistics-wrapper month-statistics">
          <div class="schedule-satisfaction-header">
            <div class="month-switcher">
              <button
                type="button"
                class="prev"
                :title="t('datetime.Previous month')"
                @click="handlePreviousMonth"
              >
                <AlgIcon
                  name="chevron-left"
                  size="m"
                />
              </button>
              <div class="month">
                {{ dayjs(date).format(DTF.MONTH_YEAR) }}
              </div>
              <button
                type="button"
                class="next"
                :title="t('datetime.Next month')"
                @click="handleNextMonth"
              >
                <AlgIcon
                  name="chevron-right"
                  size="m"
                />
              </button>
            </div>
          </div>
          <div class="schedule-satisfaction-content">
            <div class="statistics-wrapper main-statistics">
              <AlgSpinner
                v-if="scheduleSatisfactions.isFetching"
                size="l"
              />
              <template v-else>
                <CanvasDonut
                  v-if="scheduleSatisfactions.data.length"
                  direction="horizontal"
                  :size="80"
                  :colors="satisfactionColors"
                  :series="scheduleSatisfactionSeries"
                  :labels="scheduleSatisfactionLabels"
                />
                <p
                  v-else
                  class="no-data"
                >
                  {{ t('common.No data') }}
                </p>
              </template>
            </div>
            <div class="weeks">
              <div
                v-for="(week, k) in weeks"
                :key="k"
                class="week"
              >
                <div class="left">
                  <span class="week-number">
                    {{ t('datetime.Week {week}', { week: getWeekNumber(week.number) }) }}
                  </span>
                  <span class="period">
                    {{
                      t('datetime.{start}, {end}', {
                        start: dayjs(date.week(week.number))
                          .isoWeekday(1)
                          .format(t(`datetime.${DTF.MONTH_DAY}`)),
                        end: dayjs(date.week(week.number))
                          .isoWeekday(7)
                          .format(t(`datetime.${DTF.MONTH_DAY}`))
                      })
                    }}
                  </span>
                </div>
                <div class="separator" />
                <div class="right">
                  <AlgSpinner
                    v-if="scheduleSatisfactions.isFetching"
                  />
                  <template v-else>
                    <template v-if="week.satisfaction">
                      <CanvasDonut
                        direction="horizontal"
                        :size="40"
                        :line-width="6"
                        :colors="satisfactionColors"
                        :series="getSatisfactionSeries(week.satisfaction)"
                        :labels="[]"
                        :legend="false"
                      />
                      <ul class="legend">
                        <li v-html="getWeekSatisfiedLegend(week.satisfaction)" />
                        <li v-html="getWeekNotSatisfiedLegend(week.satisfaction)" />
                      </ul>
                    </template>
                    <p
                      v-else
                      class="no-data"
                    >
                      {{ t('common.No data') }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./index.scss" />

<style lang="scss" scoped>
  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--alg-spacing-l);

    .column {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    @media (min-width: $w-medium-s) {
      flex-direction: row;

      .column {
        &.left {
          width: 40%;
        }

        &.right {
          width: 60%;
        }
      }
    }
  }

  .statistics-wrapper {
    display: flex;
    box-sizing: border-box;
    padding: var(--alg-spacing-s);
    border: 1px solid var(--alg-color-surface-border);
    border-radius: var(--alg-effect-radius-s);

    &.main-statistics {
      min-height: 98px;
      align-items: center;
      justify-content: center;
    }

    &.month-statistics {
      flex-direction: column;
      padding: var(--alg-spacing-m);
    }

    .schedule-satisfaction-header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: var(--alg-spacing-m);

      .subtitle {
        margin-bottom: 0;
      }

      .month-switcher {
        display: flex;
        align-items: center;
        gap: var(--alg-spacing-m);

        .prev,
        .next {
          padding: 0;
        }

        .month {
          width: 120px;
          font-size: var(--alg-font-size-m);
          font-weight: var(--alg-font-weight-bold);
          text-align: center;
          text-transform: capitalize;
        }
      }
    }

    .schedule-satisfaction-content {
      display: flex;
      flex-direction: column;

      .statistics-wrapper {
        min-height: 98px;
        margin-bottom: var(--alg-spacing-l);
      }

      .no-data {
        display: flex;
        height: 80px;
        align-items: center;
        justify-content: center;
        color: var(--alg-color-text-secondary);
        font-size: var(--alg-font-size-l);
        text-align: center;
      }

      .weeks {
        display: flex;
        flex-direction: column;
        gap: var(--alg-spacing-s);

        .week {
          display: flex;
          height: 58px;
          box-sizing: border-box;
          align-items: center;
          padding: var(--alg-spacing-s) var(--alg-spacing-m);
          border: 1px solid var(--alg-color-surface-border);
          border-radius: var(--alg-effect-radius-s);

          .left {
            display: flex;
            flex-direction: column;
            gap: var(--alg-spacing-s);

            .week-number {
              font-size: var(--alg-font-size-m);
              font-weight: var(--alg-font-weight-bold);
            }

            .period {
              color: var(--alg-color-text-secondary);
              font-size: var(--alg-font-size-s);
            }
          }

          .separator {
            display: block;
            width: 1px;
            align-self: stretch;
            margin: 0 var(--alg-spacing-m);
            background-color: var(--alg-color-surface-border);
          }

          .right {
            display: flex;
            flex: 1 1 auto;
            align-items: center;
            justify-content: center;

            .legend {
              display: flex;
              flex: 1 1 auto;
              flex-direction: column;
              margin-left: var(--alg-spacing-s);
              font-size: var(--alg-font-size-s);
              gap: var(--alg-spacing-xs);
            }

            .no-data {
              display: flex;
              height: auto;
              align-items: center;
              justify-content: center;
              color: var(--alg-color-text-secondary);
              font-size: var(--alg-font-size-s);
              text-align: center;
            }
          }
        }
      }
    }
  }
</style>
