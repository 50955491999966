import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, useRoute } from 'vue-router'

type BreadcrumbItem = RouteLocationRaw & {
  label: string
  inactive: boolean
}

const breadcrumbParam = ref<Record<string, string>>({})

export function useBreadcrumb() {
  const route = useRoute()

  const { t } = useI18n()

  function addBreadcrumbParam(key: string, value: string) {
    breadcrumbParam.value[key] = value
  }

  const breadcrumb = computed((): BreadcrumbItem[] => {
    const parametersRegex = /^:\w*$/

    const dynamicBreadcrumb = route.matched
      .filter((r) => 'breadcrumb' in r.meta)
      .map((r) => {
        const match = r.meta.breadcrumb!.match(parametersRegex)

        return {
          name: r.name,
          label: match ? breadcrumbParam.value[match[0]] : t(`${r.meta.breadcrumb!}`),
          inactive: r.meta.breadcrumbInactive ?? false,
          query: route.query,
        }
      })

    return dynamicBreadcrumb
  })

  return { breadcrumb, addBreadcrumbParam }
}
