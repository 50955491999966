enum UserPermissions {
  ACCEPT_OR_DENY_PENDING_ABSENCES = 'accept_or_deny_pending_absences',
  ACCEPT_OR_DENY_PENDING_ABSENCES_ON_CRITICAL_PERIODS = 'accept_or_deny_pending_absences_on_critical_periods',
  ACCESS_ABSENCES_ON_CRITICAL_PERIODS = 'access_absences_on_critical_periods',
  ACCESS_CALENDAR = 'access_calendar',
  ACCESS_CUSTOMER_ADVISERS = 'access_customer_advisers',
  ACCESS_EXPORTS = 'access_exports',
  ACCESS_HYPER_PERIODS = 'access_hyper_periods',
  ACCESS_INTERNAL_TASKS = 'access_internal_tasks',
  ACCESS_OPERATIONAL_SUBDIVISION_STATISTICS = 'access_operational_subdivision_statistics',
  ACCESS_OTHER_USERS = 'access_other_users',
  ACCESS_PROJECT_STATISTICS = 'access_project_statistics',
  ACCESS_SCHEDULING = 'access_scheduling',
  ACCESS_SCHEDULING_PARAMETERS = 'access_scheduling_parameters',
  ACCESS_STATISTICS = 'access_statistics',
  ACCESS_TAGS = 'access_tags',
  ACCESS_TRAINERS = 'access_trainers',
  ACCESS_TRAINING = 'access_training',
  ACCESS_VALIDATION_PROCESSES = 'access_validation_processes',
  ACCESS_WORK_CYCLES = 'access_work_cycles',
  ARBITRATE_ABSENCES_ON_CRITICAL_PERIODS = 'arbitrate_absences_on_critical_periods',
  CREATE_ABSENCES_FOR_OTHER = 'create_absences_for_other',
  IMPORT_LEAVE_BALANCES = 'import_leave_balances',
  MANAGE_ACTIVITIES = 'manage_activities',
  MANAGE_CUSTOMER_ADVISERS = 'manage_customer_advisers',
  MANAGE_HYPER_PERIODS = 'manage_hyper_periods',
  MANAGE_INDIVIDUAL_SCHEDULING = 'manage_individual_scheduling',
  MANAGE_INTERNAL_TASKS = 'manage_internal_tasks',
  MANAGE_MANUAL_SCHEDULING = 'manage_manual_scheduling',
  MANAGE_PROJECTS = 'manage_projects',
  MANAGE_ROLES = 'manage_roles',
  MANAGE_RULES_AND_AMBITIONS = 'manage_rules_and_ambitions',
  MANAGE_SCHEDULING = 'manage_scheduling',
  MANAGE_SCHEDULING_PARAMETERS = 'manage_scheduling_parameters',
  MANAGE_SUBDIVISIONS = 'manage_subdivisions',
  MANAGE_TAGS = 'manage_tags',
  MANAGE_USERS = 'manage_users',
  MANAGE_VALIDATION_PROCESSES = 'manage_validation_processes',
  MANAGE_WORK_CYCLES = 'manage_work_cycles',
  SEE_ABSENCES = 'see_absences',
  SEE_GLOBAL_PLANNING = 'see_global_planning',
  SEE_GLOBAL_PLANNING_FOR_CUSTOMER_ADVISER = 'see_global_planning_for_customer_adviser',
  SEE_GLOBAL_PLANNING_FOR_TRAINER = 'see_global_planning_for_trainer',
}
export { UserPermissions }
