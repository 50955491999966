<script lang="ts" setup>
  import { AlgIllustration } from '#/components'
  import { IllustrationName } from '#/types'

  interface Props {
    readonly title: string
    readonly subtitle?: string
    readonly illustration?: IllustrationName
    readonly illustrationSize?: [number, number] | number
    readonly light?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    illustrationSize: 128,
    light: false,
  })
</script>

<template>
  <div
    class="no-data"
    :class="{ light: props.light }"
  >
    <AlgIllustration
      v-if="props.illustration"
      :name="props.illustration"
      :size="props.illustrationSize"
    />
    <p class="no-data-title">
      {{ props.title }}
    </p>
    <p
      v-if="props.subtitle"
      class="no-data-subtitle"
    >
      {{ props.subtitle }}
    </p>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--alg-spacing-m);
    line-height: var(--alg-font-line-height);
    text-align: center;

    .no-data-title {
      margin: 0;
      color: var(--alg-color-text-primary);
      font-size: var(--alg-font-size-l);
    }

    .no-data-subtitle {
      margin: 0;
      color: var(--alg-color-text-secondary);
      font-size: var(--alg-font-size-m);
    }

    &.light {
      .no-data-title,
      .no-data-subtitle {
        color: var(--alg-color-text-light);
      }
    }
  }
</style>
