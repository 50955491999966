import dayjs from 'dayjs'
import en from 'dayjs/locale/en'
import fr from 'dayjs/locale/fr'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import toObject from 'dayjs/plugin/toObject'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'

import { i18n } from '@/plugins/i18n'

const locale = i18n.global.locale.value === 'fr' ? fr : en

/**
 * @deprecated
 */
const _dayjs = dayjs

_dayjs.extend(advancedFormat)
_dayjs.extend(customParseFormat)
_dayjs.extend(duration)
_dayjs.extend(isBetween)
_dayjs.extend(isLeapYear)
_dayjs.extend(isSameOrBefore)
_dayjs.extend(isToday)
_dayjs.extend(isoWeek)
_dayjs.extend(isoWeeksInYear)
_dayjs.extend(localeData)
_dayjs.extend(localizedFormat)
_dayjs.extend(toObject)
_dayjs.extend(weekOfYear)
_dayjs.extend(weekday)

_dayjs.locale(i18n.global.locale.value, {
  ...locale,
  weekStart: 1,
})

declare module 'dayjs' {
  interface Dayjs {
    format<T extends string>(template: T): T
  }
}

export { _dayjs as dayjs }
