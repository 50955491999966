<script setup lang="ts">
  import { computed } from 'vue'

  import type { IconSize } from '#/types'
  import { calcIconSize } from '#/utils'

  type Props = {
    size?: IconSize
    color?: string
  }

  defineOptions({
    name: 'AlgSpinner',
  })

  const props = withDefaults(defineProps<Props>(), {
    size: 'm',
    color: 'currentColor',
  })

  const iconSize = computed(() => calcIconSize(props.size))
</script>

<template>
  <svg
    class="spinner"
    aria-hidden="true"
    :height="iconSize"
    :width="iconSize"
    viewBox="0 0 16 16"
    :fill="props.color"
  >
    <g class="icon">
      <path
        d="M8 16c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zM8 2C4.691 2 2 4.691 2 8s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6z"
        opacity=".4"
      />
      <path d="M16 8h-2c0-3.309-2.691-6-6-6V0c4.411 0 8 3.589 8 8z" />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
  @keyframes rotate {
    0% {
      transform: rotate(0turn);
    }

    100% {
      transform: rotate(1turn);
    }
  }

  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      animation: linear rotate 0.75s infinite;
      transform-origin: center;
    }
  }
</style>
