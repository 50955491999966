<script setup lang="ts">
  import { ref, useSlots } from 'vue'

  import { Nullable } from '@algorh/shared'

  import { SwipeAction } from './List.types'

  import { AlgIcon } from '#/index'

  type Props = {
    active?: boolean
    disabled?: boolean
    clickable?: boolean
    swipeable?: boolean
    swipeActions?: SwipeAction[]
  }

  defineOptions({
    name: 'AlgListItem',
  })

  const props = withDefaults(defineProps<Props>(), {
    active: false,
    disabled: false,
    clickable: false,
    swipeable: false,
    swipeActions: () => [],
  })

  const emit = defineEmits<{
    (e: 'click', payload?: Event): void
  }>()

  const slots = useSlots()

  const listItem = ref<Nullable<HTMLElement>>(null)

  function handleActionClick(action: SwipeAction) {
    // TODO: Ça marche pas quand y'a le callback
    if (listItem.value) {
      listItem.value.scrollTo({ left: 0, behavior: 'smooth' })
    }

    setTimeout(() => {
      action.callback()
    }, 300)
  }

  function handleItemClick(e: Event) {
    if (!props.clickable) {
      return
    }

    emit('click', e)
  }
</script>

<template>
  <div
    ref="listItem"
    class="list-item"
    :class="{
      active: props.active,
      disabled: props.disabled,
      clickable: props.clickable,
      swipeable: props.swipeable
    }"
    tabindex="0"
    @click="handleItemClick"
  >
    <div
      v-if="slots.prepend"
      class="list-item-prepend"
    >
      <slot name="prepend" />
    </div>
    <div
      ref="listItemContent"
      class="list-item-content"
    >
      <div
        v-if="slots.title"
        class="list-item-title"
      >
        <slot name="title" />
      </div>
      <div
        v-if="slots.description"
        class="list-item-description"
      >
        <slot name="description" />
      </div>
    </div>
    <div
      v-if="slots.append"
      class="list-item-append"
    >
      <slot name="append" />
    </div>
    <div
      v-if="props.swipeable && props.swipeActions.length"
      class="list-item-action"
    >
      <button
        v-for="(action, k) in swipeActions"
        :key="k"
        type="button"
        class="list-item-action-button"
        :title="action.label"
        :style="{ backgroundColor: action.color }"
        @click="() => handleActionClick(action)"
      >
        <AlgIcon
          v-if="action.icon"
          :name="action.icon"
        />
        <template v-else>
          {{ action.label }}
        </template>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .list-item {
    display: flex;
    flex-direction: row;

    .list-item-content {
      display: flex;
      box-sizing: border-box;
      flex: 1 1 auto;
      flex-direction: column;
      padding: var(--alg-spacing-m);
      gap: var(--alg-spacing-s);

      .list-item-title {
        font-size: var(--alg-font-size-l);
        font-weight: var(--alg-font-weight-bold);
      }

      .list-item-description {
        color: var(--alg-color-text-secondary);
        font-size: var(--alg-font-size-m);
      }
    }

    .list-item-prepend {
      padding: var(--alg-spacing-m) 0 var(--alg-spacing-m) var(--alg-spacing-m);
    }

    .list-item-append {
      padding: var(--alg-spacing-m) var(--alg-spacing-m) var(--alg-spacing-s) 0;
    }

    &.disabled {
      opacity: 0.5;
    }

    &.active {
      background-color: var(--alg-color-surface-background-highlight);
      opacity: 1;
    }

    &.clickable {
      cursor: pointer;
    }

    &.swipeable {
      overflow-x: scroll;
      scroll-snap-type: x proximity;

      .list-item-content {
        min-width: 100%;
        scroll-snap-align: end;
      }

      .list-item-action {
        display: flex;
        min-width: 35%;
        background-color: var(--alg-color-surface-background);

        .list-item-action-button {
          display: flex;
          flex: 1 1 auto;
          align-items: center;
          justify-content: center;
          color: var(--alg-color-text-on-color);
          font-weight: var(--alg-font-weight-bold);

          &:active {
            opacity: 0.75;
          }
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    & + & {
      border-top: 0.063rem solid var(--alg-color-surface-border);
    }
  }
</style>
