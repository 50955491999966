import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

const _dayjs = dayjs

_dayjs.extend(isBetween)

declare module 'dayjs' {
  interface Dayjs {
    format<T extends string>(template: T): T
  }
}

export { _dayjs as dayjs }
