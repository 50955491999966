<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AlgIllustration, AlgModal } from '@algorh/ui'

  const emit = defineEmits<{
    (e: 'close'): void
  }>()

  const { t } = useI18n()

  const handleClose = () => {
    emit('close')
  }
</script>

<template>
  <AlgModal
    name="internal-error"
    size="m"
    :allow-cancel="false"
    :confirm-button-text="t('common.Continue')"
    @close="handleClose"
    @confirm="handleClose"
  >
    <template #title>
      {{ t('errors.Internal error') }}
    </template>
    <template #content>
      <div class="content-wrapper">
        <AlgIllustration
          name="error"
          :size="148"
        />
        <p class="subtitle">
          {{ t('errors.An internal error has occurred, please try again later') }}
        </p>
        <p>{{ t('errors.If the problem persists, please contact your administrator') }}</p>
      </div>
    </template>
  </AlgModal>
</template>

<style lang="scss" scoped>
  @import './AlertModal';
</style>
