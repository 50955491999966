import { createRouter, createWebHistory } from 'vue-router'

import { absenceRouter } from '@/sections/absences/routes'
import { documentationRouter } from '@/sections/documentation/routes'
import { exportsRouter } from '@/sections/exports/router'
import { settingsRouter } from '@/sections/settings/router'
import { statisticsRouter } from '@/sections/statistics/routes'
import { stockExchangeRouter } from '@/sections/stock-exchange/routes'
import { trainerRouter } from '@/sections/training/router'
import { usersRouter } from '@/sections/users/router'

import { projectsRouter } from '../sections/projects/router'

import { authRouter } from './sections/auth'
import { globalPlanningRouter } from './sections/global-planning'
import { myPlanningRouter } from './sections/my-planning'
import { notificationsRouter } from './sections/notifications'
import { schedulingRouter } from './sections/scheduling'
import { supportRouter } from './sections/support'
import { navigationManager } from './navigationManager'

export const router = () => {
  const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'home',
        children: [
          ...schedulingRouter,
          ...authRouter,
          ...myPlanningRouter,
          ...documentationRouter,
          ...stockExchangeRouter,
          ...settingsRouter,
          ...usersRouter,
          ...exportsRouter,
          ...statisticsRouter,
          ...supportRouter,
          ...absenceRouter,
          ...projectsRouter,
          ...globalPlanningRouter,
          ...notificationsRouter,
          ...trainerRouter,
        ],
      },
      {
        path: '/:pathMatch(.*)',
        name: 'error',
        component: () => import('@/sections/error/Error.vue'),
        props: { code: 404 },
      },
      {
        path: '/forbidden',
        name: 'forbidden',
        component: () => import('@/sections/error/Error.vue'),
        props: { code: 403 },
      },
    ],
  })

  router.beforeEach(async (...args) => await navigationManager(router, args))

  return router
}
