import dayjs from 'dayjs'
import en from 'dayjs/locale/en'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import toObject from 'dayjs/plugin/toObject'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'

import { getLocale } from './locale'

const locale = getLocale()
const _dayjs = dayjs as typeof dayjs

async function getDayjsLocal(locale: string) {
  switch (locale) {
    case 'fr':
      return import('dayjs/locale/fr')

    case 'it':
      return import('dayjs/locale/it')

    case 'en':
    default:
      return en
  }
}

_dayjs.extend(localeData)
_dayjs.extend(advancedFormat)
_dayjs.extend(weekOfYear)
_dayjs.extend(duration)
_dayjs.extend(customParseFormat)
_dayjs.extend(isoWeek)
_dayjs.extend(isSameOrBefore)
_dayjs.extend(toObject)
_dayjs.extend(isToday)
_dayjs.extend(weekday)
_dayjs.extend(isBetween)
_dayjs.extend(localizedFormat)
_dayjs.extend(isoWeeksInYear)
_dayjs.extend(isLeapYear)

// Don't use top-level await
; (async () => {
  const dayjsLocale = await getDayjsLocal(locale)
  _dayjs.locale(locale, {
    ...dayjsLocale,
    weekStart: 1,
  })
})()

declare module 'dayjs' {
  interface Dayjs {
    format<T extends string>(template: T): T
  }
}

export { _dayjs as dayjs }
