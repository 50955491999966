import { RouteRecordRaw, RouterView } from 'vue-router'

import { UserPermissions } from '@/core/enums'
import AppLayout from '@/layouts/app/App.vue'

const schedulingRouter: RouteRecordRaw[] = [
  {
    path: 'scheduling',
    name: 'scheduling',
    meta: {
      permissions: [UserPermissions.ACCESS_SCHEDULING],
      redirectable: true,
      weight: 100,
      layout: AppLayout,
      breadcrumb: 'scheduling.Scheduling',
      breadcrumbInactive: true,
    },
    component: () => import('@/sections/subdivisions/Scheduling.vue'),
    children: [
      {
        name: 'scheduling-subdivision',
        path: 'subdivision/:subdivisionId?',
        meta: {
          breadcrumb: ':subdivisionId',
        },
        redirect: { name: 'dashboard' },
        children: [
          {
            name: 'parameters',
            meta: {
              permissions: [UserPermissions.ACCESS_SCHEDULING_PARAMETERS],
              breadcrumb: 'scheduling.Parameters',
            },
            redirect: { name: 'subdivision-arbitration' },
            component: () => import('@/sections/subdivisions/parameters/Parameters.vue'),
            path: 'parameters',
            children: [
              {
                name: 'subdivision-arbitration',
                path: 'arbitration',
                meta: {
                  breadcrumb: 'scheduling.Arbitration',
                },
                component: () =>
                  import('@/sections/subdivisions/parameters/partials/arbitration/Arbitration.vue'),
              },
              {
                name: 'subdivision-rules',
                path: 'rules',
                meta: {
                  breadcrumb: 'rules.Collective rules',
                },
                component: () =>
                  import('@/sections/subdivisions/parameters/partials/SubdivisionRulesManager.vue'),
              },
              {
                name: 'subdivision-ambitions',
                path: 'ambitions',
                meta: {
                  breadcrumb: 'ambitions.Ambitions',
                },
                component: () =>
                  import(
                    '@/sections/subdivisions/parameters/partials/SubdivisionAmbitionsManager.vue'
                  ),
              },
            ],
          },
          {
            name: 'dashboard',
            meta: {
              permissions: [UserPermissions.ACCESS_SCHEDULING],
            },
            redirect: { name: 'production' },
            path: 'dashboard',
            props: true,
            children: [
              {
                path: 'internal-tasks',
                name: 'internal-tasks',
                redirect: { name: 'internal-tasks-list' },
                meta: {
                  breadcrumb: 'internalTasks.Internal tasks',
                  permissions: [UserPermissions.ACCESS_INTERNAL_TASKS],
                },
                component: RouterView,
                children: [
                  {
                    name: 'internal-tasks-list',
                    component: () => import('@/sections/subdivisions/internal-tasks/InternalTasks.vue'),
                    path: '',
                  },
                  {
                    name: 'internal-tasks-automated-scheduling',
                    meta: {
                      permissions: [UserPermissions.MANAGE_INTERNAL_TASKS],
                      breadcrumb: 'scheduling.Automated scheduling',
                    },
                    component: () =>
                      import('@/sections/subdivisions/internal-tasks/AutomatedScheduling.vue'),
                    path: 'automated-scheduling',
                  },
                  {
                    name: 'internal-tasks-manual-scheduling',
                    meta: {
                      permissions: [UserPermissions.MANAGE_INTERNAL_TASKS],
                      breadcrumb: 'scheduling.Manual scheduling',

                    },
                    component: () =>
                      import('@/sections/subdivisions/internal-tasks/ManualScheduling.vue'),
                    path: 'manual-scheduling',
                  },
                  {
                    name: 'internal-tasks-validation',
                    meta: {
                      permissions: [UserPermissions.MANAGE_INTERNAL_TASKS],
                      breadcrumb: 'absences.Validation',
                    },
                    component: () =>
                      import('@/sections/subdivisions/internal-tasks/InternalTaskValidation.vue'),
                    path: 'validation',
                  },
                ],
              },
              {
                name: 'production',
                meta: {
                  permissions: [
                    UserPermissions.ACCESS_SCHEDULING,
                    UserPermissions.MANAGE_SCHEDULING,
                  ],
                  breadcrumb: 'scheduling.Production',
                },
                component: () => import('@/sections/subdivisions/dashboard/Dashboard.vue'),
                path: 'production/:date?',
              },
              {
                name: 'manual-scheduling',
                meta: {
                  permissions: [UserPermissions.MANAGE_MANUAL_SCHEDULING],
                  breadcrumb: 'scheduling.Manual scheduling',
                },
                component: RouterView,
                path: 'manual-scheduling',
                redirect: { name: 'manual-scheduling-form' },
                children: [
                  {
                    name: 'manual-scheduling-form',
                    component: () =>
                      import('@/sections/subdivisions/manual-scheduling/ManualSchedulingForm.vue'),
                    path: '',
                  },
                  {
                    name: 'manual-scheduling-validation',
                    meta: {
                      breadcrumb: 'scheduling.Manual scheduling validation',
                    },
                    component: () =>
                      import(
                        '@/sections/subdivisions/manual-scheduling/ManualSchedulingPreValidation.vue'
                      ),
                    path: 'validation',
                  },
                ],
              },
              {
                name: 'internal-task-instances',
                path: 'internal-task-instances',
                redirect: (to) => ({
                  name: 'internal-task-instances-type',
                  params: {
                    subdivisionId: to.params.subdivisionId,
                    internalTaskInstanceType: to.params.internalTaskInstanceType || 'training',
                  },
                }),
                meta: {
                  permissions: [
                    UserPermissions.ACCESS_INTERNAL_TASKS || UserPermissions.MANAGE_INTERNAL_TASKS,
                  ],
                  breadcrumb: 'internalTasks.Internal tasks',
                },
                children: [
                  {
                    name: 'internal-task-instances-type',
                    path: ':internalTaskInstanceType',
                    meta: {
                      breadcrumb: ':internalTaskInstanceType',
                    },
                    component: () =>
                      import('@/sections/subdivisions/internal-tasks/InternalTaskInstances.vue'),
                    children: [
                      {
                        path: ':internalTaskInstanceId',
                        name: 'internal-task-instance-detail',
                        component: () =>
                          import('@/sections/subdivisions/internal-tasks/InternalTaskInstance.vue'),
                      },
                    ],
                  },
                  {
                    path: ':internalTaskInstanceType/:internalTaskInstanceId/list-of-participants',
                    name: 'internal-task-list-of-participants',
                    meta: {
                      breadcrumb: ':internalTaskInstanceType',
                    },
                    component: () =>
                      import(
                        '@/sections/subdivisions/internal-tasks/InternalTaskInstancesListOfParticipants.vue'
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]

export { schedulingRouter }
