import type { NavigationGuardWithThis, RouteLocationNormalized, Router, RouteRecordNormalized } from 'vue-router'
import * as Sentry from '@sentry/vue'

import { Nullable } from '@algorh/shared'

import { UserPermissions } from '@/core/enums'
import { MeApiService } from '@/core/services'
import { queryClient } from '@/queryClient'

const DEFAULT_ROUTE = { name: 'landing' }

const canAccessRoute = (
  { meta }: RouteRecordNormalized | RouteLocationNormalized,
  permissions: Nullable<Record<UserPermissions, boolean>>,
) => !meta.permissions || meta.permissions.some((p) => permissions?.[p])

export function userLandingPage(
  router: Router,
  permissions: Nullable<Record<UserPermissions, boolean>>,
) {
  if (permissions === null || !Object.keys(permissions).length) {
    return DEFAULT_ROUTE
  }

  const canAccess = (route: RouteRecordNormalized) =>
    route.meta?.redirectable
    && canAccessRoute(route, permissions)

  const route = router
    .getRoutes()
    .filter(canAccess)
    .sort((a, b) => ((b.meta?.weight ?? 0) - (a.meta?.weight ?? 0)))[0]
  return route ?? DEFAULT_ROUTE
}

export async function navigationManager(router: Router, args: Parameters<NavigationGuardWithThis<undefined>>) {
  const [to, , next] = args

  const me = await queryClient.fetchQuery({
    queryKey: ['me'],
    queryFn: MeApiService.getMe,
    staleTime: 24 * 60 * 60 * 1000, // 24H
  })
  if (!me) {
    Sentry.captureException(`Invalid me value after fetch : (${JSON.stringify(me)})`)
    return
  }
  const user = me.data
  const permissions = me.data?.permissions ?? null
  const shoudRedirect = ['home', 'login', 'password-forgot', 'password-init', 'password-reset', 'missing-user', 'external-failure'].includes(to.name as string)

  if (!user) {
    // Si la page demandé ne requiert pas d'authent on next(), sinon on redirige sur /login
    return to.meta.unauthenticated ? next() : next({ name: 'login' })
  }

  if (!canAccessRoute(to, permissions)) {
    // Si la page demandé ne fait pas partie des permissions du user on redirige sur /forbidden
    return next({ name: 'forbidden', replace: true })
  }

  if (user && shoudRedirect) {
    // Si l'utilisateur est connecté et qu'il vient d'une page d'authent ou de la home alors on redirige selon ses permissions (le login renvoie vers home)
    return next(userLandingPage(router, permissions))
  }

  // Si le user est connecté et que la page demandé est une page de l'app on next()
  next()
}
