import { RouterView } from 'vue-router'

import { UserPermissions } from '@/core/enums'
import AppLayout from '@/layouts/app/App.vue'

const trainerRouter = [
  {
    path: 'trainer',
    name: 'trainer',
    component: RouterView,
    meta: {
      layout: AppLayout,
      permissions: [
        UserPermissions.ACCESS_TRAINING,
      ],
    },
    children: [
      {
        path: 'training-list',
        name: 'training-list',
        component: () =>
          import('./pages/training-list/TrainingList.vue'),
        meta: {
          breadcrumb: 'trainer.Past and planned internal tasks',
        },
        children: [
          {
            path: ':internalTaskInstanceId',
            name: 'training-detail',
            component: () =>
              import('@/sections/subdivisions/internal-tasks/InternalTaskInstance.vue'),
          },
        ],
      },
    ],
  },
]

export { trainerRouter }
