enum ActivityCategory {
  ABSENCE = 'absence',
  DAY_OFF = 'day_off',
  LUNCH_BREAK = 'lunch_break',
  NON_PRODUCTION = 'non_production',
  PRODUCTION = 'production',
  TRAINING = 'training',
  UNKNOWN = 'unknown',
}
export { ActivityCategory }
