import { CalendarConfig } from '@/interfaces'
import { fetchJson } from '@/utils/fetch'

const BASE_URI = '/api/calendar'

const CalendarApiService = {
  getCalendarConfig() {
    return fetchJson<CalendarConfig>('GET', `${BASE_URI}/config`)
  },
}

export { CalendarApiService }
