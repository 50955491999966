import { DateString, Nullable } from '@algorh/shared'

import { Dto } from '@/interfaces'
import { fetchJson } from '@/utils/fetch'

import { TrainingDomain, TrainingType } from '../interfaces/InternalTask'
import { SchedulingConfig } from '../interfaces/Scheduling'

const BASE_URI = '/api/scheduling'

const SchedulingApiService = {
  getSchedulingConfig() {
    return fetchJson<SchedulingConfig>('GET', `${BASE_URI}/config`)
  },
  getTrainingTypes() {
    return fetchJson<TrainingType[]>('GET', `${BASE_URI}/training-types`)
  },
  getTrainingDomains() {
    return fetchJson<TrainingDomain[]>('GET', `${BASE_URI}/training-domains`)
  },
  putInternalTaskInstanceGlobalDetails(
    id: number,
    payload: { name: string, color: string, code: Nullable<string>, description: Nullable<string> },
  ) {
    return fetchJson('PUT', `${BASE_URI}/internal-tasks/${id}`, payload)
  },
  getAvailableTrainers(subdivisionId: number, dto: Dto<{ start_date: DateString, end_date: DateString }>) {
    return fetchJson<{ id: number, full_name: string }[]>('GET', `${BASE_URI}/operational-subdivisions/${subdivisionId}/trainers`, null, dto)
  },
}

export { SchedulingApiService }
