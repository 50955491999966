<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { InputSize } from '..'

  import { Size, Weight } from '#/types'

  type Props = {
    readonly label: string
    readonly htmlFor?: string
    readonly sublabel?: string
    readonly required?: boolean
    readonly disabled?: boolean
    readonly errored?: boolean
    readonly inline?: boolean
    readonly inputSize?: InputSize
    readonly fontSize?: Size
    readonly fontWeight?: Weight
  }

  defineOptions({
    name: 'AlgLabel',
  })

  const props = withDefaults(defineProps<Props>(), {
    required: false,
    disabled: false,
    errored: false,
    inline: false,
    fontSize: 'm',
    fontWeight: 'bold',
  })

  const { t } = useI18n()
</script>

<template>
  <label
    class="input-label"
    :class="[
      props.inputSize ? `size-${props.inputSize}` : '',
      {
        inline: props.inline,
        disabled: props.disabled,
        errored: props.errored,
      }
    ]"
    :for="props.htmlFor"
  >
    <span
      class="input-label-inner"
      :style="{
        fontSize: `var(--alg-font-size-${props.fontSize})`,
        fontWeight: `var(--alg-font-weight-${props.fontWeight})`
      }"
    >
      {{ props.label }}
      <span
        v-if="props.required"
        :title="t('common.This field is required')"
      >*</span>
    </span>
    <span
      v-if="props.sublabel"
      class="sublabel"
    >
      {{ props.sublabel }}
    </span>
  </label>
</template>

<style lang="scss" scoped>
  .input-label {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--alg-spacing-s);
    cursor: inherit;

    .input-label-inner {
      overflow: hidden;
      line-height: var(--alg-font-line-height);
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .sublabel {
      margin-top: var(--alg-spacing-xs);
      color: var(--alg-color-text-secondary);
      font-size: var(--alg-font-size-s);
      font-weight: var(--alg-font-weight-regular);
      line-height: var(--alg-font-line-height);
    }

    &.inline {
      justify-content: center;
      margin-bottom: 0;

      &.size-s {
        height: 32px;

        .input-label-inner {
          line-height: 32px;
        }
      }

      &.size-m {
        height: 40px;

        .input-label-inner {
          line-height: 40px;
        }
      }

      &.size-l {
        height: 48px;

        .input-label-inner {
          line-height: 48px;
        }
      }
    }

    &.disabled {
      color: var(--alg-color-text-secondary);
    }

    &.errored {
      color: var(--alg-color-state-danger);
    }
  }
</style>
