import { RouteRecordRaw } from 'vue-router'

import { UserPermissions } from '@/core/enums'
import AppLayout from '@/layouts/app/App.vue'

const globalPlanningRouter: RouteRecordRaw[] = [
  {
    name: 'global-planning',
    path: 'global-planning/:view?/:year?/:month?/:week?/:day?',
    component: () => import('@/sections/planning/pages/GlobalPlanning.vue'),
    meta: {
      redirectable: true,
      weight: 1,
      permissions: [UserPermissions.SEE_GLOBAL_PLANNING_FOR_CUSTOMER_ADVISER, UserPermissions.SEE_GLOBAL_PLANNING_FOR_TRAINER],
      layout: AppLayout,
      breadcrumb: 'scheduling.Global planning',
    },
  },
]

export { globalPlanningRouter }
