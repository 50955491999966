<script lang="ts" setup>
  import { computed, onMounted, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  import { Nullable } from '@algorh/shared'
  import { AlgButton, AlgIllustration, AlgModal } from '@algorh/ui'

  import SubMenu, { MenuItem } from '@/components/SubMenu.vue'
  import UserInfos from '@/components/UserInfos.vue'
  import { useAppInit } from '@/composables/useAppInit'
  import { useConfigs } from '@/composables/useConfigs'
  import { UserPermissions } from '@/core/enums'

  import AccountInfos from './partials/AccountInfos.vue'
  import AccountSecurity from './partials/AccountSecurity.vue'
  import AccountStatistics from './partials/AccountStatistics.vue'

  type Props = {
    readonly section: string
  }

  const props = withDefaults(defineProps<Props>(), {
    section: 'infos',
  })

  const emit = defineEmits<{
    (e: 'close'): void
  }>()

  // Composables
  const router = useRouter()

  const { t } = useI18n()

  // Data
  const { permissions, me } = useAppInit()
  const { auth: authConfig, supportEnabled } = useConfigs()

  // Refs
  const focusedSubMenuItem = ref<Nullable<string | number>>(null)

  // Computed
  const menuItems = computed(() => {
    const items = [
      {
        content: t('account.My info'),
        id: 'infos',
      },
    ]

    if (!authConfig.value?.data?.authentication_system) {
      items.push({ content: t('account.My security'), id: 'security' })
    }

    if (permissions.value?.[UserPermissions.ACCESS_CALENDAR]) {
      items.push({
        content: t('account.My statistics'),
        id: 'statistics',
      })
    }

    return items
  })

  function handleChangeMenu(item: MenuItem<string>) {
    focusedSubMenuItem.value = item.id
  }

  function handleClose() {
    emit('close')
  }

  function handleContactUs() {
    router.push('/support')
  }

  onMounted(async () => {
    focusedSubMenuItem.value = props.section
  })
</script>

<template>
  <AlgModal
    name="account"
    size="xl"
    :footer="false"
    @close="handleClose"
  >
    <template #title>
      <span class="title-wrapper">
        <UserInfos :user="me?.data" />
      </span>
    </template>
    <template #subheader>
      <SubMenu
        class="account-header-menu"
        :menu-items="menuItems"
        :highlighted="focusedSubMenuItem"
        @on-select-item="handleChangeMenu"
      />
    </template>
    <template #content>
      <div class="content-wrapper">
        <div class="account-sections">
          <AccountInfos v-if="focusedSubMenuItem === 'infos'" />
          <AccountSecurity v-if="focusedSubMenuItem === 'security'" />
          <AccountStatistics v-if="focusedSubMenuItem === 'statistics'" />
        </div>
        <div
          v-if="supportEnabled"
          class="account-footer"
        >
          <AlgIllustration
            name="support"
            :size="40"
          />
          <p>
            {{ t('support.Do you have a problem or a question?') }}
          </p>
          <AlgButton
            size="s"
            :label="t('support.Contact us')"
            @click="handleContactUs"
          />
        </div>
      </div>
    </template>
  </AlgModal>
</template>

<style lang="scss" scoped>
  .title-wrapper {
    position: absolute;
    left: 20px;
    display: flex;
  }

  .account-sections {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .content-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;

    .account-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: var(--alg-spacing-l) 0;
      border-top: 1px solid var(--alg-color-surface-border);
      gap: var(--alg-spacing-m);
      text-align: center;
    }
  }
</style>
