import { computed, ref, watch } from 'vue'

import { useClickOutside } from './useClickOutside'
import { useHotkey } from '.'

export const FLOATING_PLACEMENTS = [
  'bottom-end',
  'bottom-start',
  'top-start',
  'top-end',
  'right-end',
  'right-start',
  'left-start',
  'left-end',
  'top',
  'bottom',
  'right',
  'left',
  'bottom-right',
  'bottom-left',
  'right-top',
  'right-bottom',
  'left-top',
  'left-bottom',
  'top-right',
  'top-left',
] as const

export type FloatingPlacement = (typeof FLOATING_PLACEMENTS)[number]

const findScrollContainer = (element: HTMLElement | null) => {
  if (!element) {
    return null
  }

  let parent = element.parentElement
  while (parent) {
    const { overflow } = window.getComputedStyle(parent)
    if (
      overflow.split(' ').every((o) => o === 'auto' || o === 'scroll')
      && parent.scrollHeight > parent.clientHeight
    ) {
      return parent
    }
    parent = parent.parentElement
  }

  return document.documentElement
}

export function useFloating(
  placement: FloatingPlacement,
  startsOpen = false,
  offset = 12,
  closeOnClickOutside = true,
  closeOnScroll = true,
  closeOnEscape = true,
  closeOnResize = true,
) {
  const isOpen = ref(startsOpen)

  const scrollY = ref(0)
  const scrollX = ref(0)
  const scrollContainer = ref<HTMLElement | null>(null)

  const onCloseCallback = ref<(() => void) | null>(null)

  const referenceX = ref<number | null>(null)
  const referenceY = ref<number | null>(null)

  const reference = ref<HTMLElement>()
  const floating = ref<HTMLElement>()

  const floatingRects = computed(() => floating.value?.getClientRects()[0] ?? { width: 0, height: 0, x: 0, y: 0 })

  const computedPosition = computed(() => calculatePosition(
    getReferenceRects(),
    correctedPlacement.value,
    offset,
  ))
  const correctedPlacement = computed(() => getCorrectedPlacement(placement))

  if (closeOnClickOutside) useClickOutside(floating, () => close(), [reference, floating])
  if (closeOnScroll) listenScroll()
  if (closeOnEscape) useHotkey('Escape', () => closeOnEscape ? close() : null)
  if (closeOnResize) addEventListener('resize', close)

  function getCorrectedPlacement(basePlacement: FloatingPlacement | FloatingPlacement[]) {
    return Array.isArray(basePlacement) ? basePlacement[0] : basePlacement
  }

  watch(reference, (newReference) => {
    if (!newReference || !isOpen.value) return
    listenScroll()
  })

  function calculatePosition(referenceRects: DOMRect, placement: FloatingPlacement, offset: number) {
    if (referenceX.value != null && referenceY.value != null) {
      return { x: referenceX.value, y: referenceY.value }
    }
    const { clientHeight } = window.document.documentElement
    const x = Math.max(
      Math.min(
        calculateXPosition(placement, referenceRects.x, referenceRects.width, floatingRects.value.width, offset),
        window.innerWidth - floatingRects.value.width),
      0,
    )
    const y = Math.min(
      Math.max(
        calculateYPosition(placement, referenceRects.y, referenceRects.height, floatingRects.value.height, offset),
        0),
      clientHeight - floatingRects.value.height,
    )

    return { x, y }
  }

  function calculateXPosition(
    placement: FloatingPlacement,
    referenceX: number,
    referenceWidth: number,
    floatingWidth: number,
    offset: number,
  ): number {
    switch (placement) {
      case 'bottom-end':
      case 'top-end':
        return referenceX + referenceWidth - floatingWidth
      case 'bottom-start':
      case 'top-start':
        return referenceX
      case 'left':
      case 'left-start':
      case 'left-end':
      case 'left-top':
      case 'left-bottom':
      case 'bottom-left':
      case 'top-left':
        return referenceX - floatingWidth - offset
      case 'right':
      case 'right-start':
      case 'right-end':
      case 'right-top':
      case 'right-bottom':
      case 'bottom-right':
      case 'top-right':
        return referenceX + referenceWidth + offset
      case 'bottom':
      case 'top':
        return referenceX - ((floatingWidth - referenceWidth) / 2)
    }
  }

  function calculateYPosition(
    placement: FloatingPlacement,
    referenceY: number,
    referenceHeight: number,
    floatingHeight: number,
    offset: number,
  ): number {
    switch (placement) {
      case 'left-start':
      case 'right-start':
        return referenceY
      case 'left-end':
      case 'right-end':
        return referenceY + referenceHeight - floatingHeight
      case 'top':
      case 'top-start':
      case 'top-end':
      case 'top-left':
      case 'top-right':
      case 'right-top':
      case 'left-top':
        return referenceY - floatingHeight - offset
      case 'bottom':
      case 'bottom-start':
      case 'bottom-end':
      case 'bottom-left':
      case 'bottom-right':
      case 'right-bottom':
      case 'left-bottom':
        return referenceY + referenceHeight + offset
      case 'left':
        return referenceY - ((floatingHeight - referenceHeight) / 2)
      case 'right':
        return referenceY - ((floatingHeight - referenceHeight) / 2)
    }
  }

  function getReferenceRects() {
    if (!floating.value) {
      // avoid mounting floating out of the screen and cause error in getClientRects
      return { width: 0, height: 0, x: 0, y: 0 } as DOMRect
    }
    return reference.value?.getClientRects()[0] ?? { width: 0, height: 0, x: 0, y: 0 } as DOMRect
  }

  function listenScroll() {
    scrollContainer.value = findScrollContainer(reference.value ?? null)

    if (!scrollContainer.value) return
    scrollContainer.value.addEventListener('scroll', onScroll)
  }

  function onScroll() {
    if (!isOpen.value) return
    close()
  }

  function open() {
    listenScroll()
    isOpen.value = true
  }

  function close() {
    referenceX.value = null
    referenceY.value = null
    if (scrollContainer.value) {
      scrollContainer.value.removeEventListener('scroll', onScroll)
    }
    if (!isOpen.value) return
    isOpen.value = false
    onCloseCallback.value?.()
  }

  function openAtPosition(x: number, y: number) {
    if (isOpen.value) return
    isOpen.value = true
    referenceX.value = Math.max(
      Math.min(
        x + floatingRects.value.width > window.innerWidth ? x - floatingRects.value.width : x,
        window.innerWidth - floatingRects.value.width,
      ),
      0,
    )
    referenceY.value = Math.max(
      Math.min(
        y + floatingRects.value.height > window.innerHeight ? y - floatingRects.value.height : y,
        window.innerHeight - floatingRects.value.height,
      ),
      0,
    )
  }

  return {
    isOpen,
    open,
    openAtPosition,
    onCloseCallback,
    reference,
    floating,
    computedPosition,
    correctedPlacement,
    scrollY,
    scrollX,
    close,
  }
}
