<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { storeToRefs } from 'pinia'

  import { AlgIcon, AlgIllustration } from '@algorh/ui'

  import { useAppStore } from '@/appStore'

  const { t } = useI18n()

  const appStore = useAppStore()

  const { appLogo, appName, appMockup } = storeToRefs(appStore)
</script>

<template>
  <div class="auth-container">
    <div class="auth-content">
      <div class="block left">
        <div class="logo">
          <AlgIllustration
            prefix="brand"
            :name="appLogo"
            :size="40"
          />
          <span class="app-name">{{ appName }}</span>
        </div>
        <slot />
      </div>
      <div class="block right">
        <div class="iphone">
          <img :src="`/assets/${appMockup}`">
          <div class="app-logo">
            <AlgIllustration
              prefix="brand"
              :name="appLogo"
              :size="40"
            />
            <div class="arrow" />
          </div>
        </div>
      </div>
    </div>
    <footer class="auth-footer">
      <p>
        {{
          t('common.{copyright} {year} - All rights reserved', {
            copyright: '© SoyHuCe',
            year: new Date().getFullYear().toString()
          })
        }}
      </p>
    </footer>
    <div class="figure figure-right">
      <div class="figure-contain">
        <div class="figure-contain-mini">
          <AlgIcon
            name="activity-mail"
            color="var(--alg-color-text-on-color)"
            size="s"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .auth-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--alg-spacing-l);
    gap: var(--alg-spacing-l);

    @media (min-width: $w-small-xl) {
      align-items: center;
    }

    .auth-content {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      .block {
        display: flex;
        width: 100%;
        max-width: 520px;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.left {
          align-items: flex-start;

          .logo {
            position: absolute;
            top: var(--alg-spacing-xxl);
            left: var(--alg-spacing-xxl);
            display: flex;
            align-items: center;
            gap: var(--alg-spacing-s);

            .app-name {
              font-size: var(--alg-font-size-l);
              font-weight: var(--alg-font-weight-bold);

              @media (min-width: $w-small-xl) {
                font-size: var(--alg-font-size-xxl);
              }
            }
          }
        }

        &.right {
          display: none;

          .iphone {
            position: relative;

            &::after {
              position: absolute;
              z-index: 0;
              bottom: 20px;
              left: 50%;
              display: block;
              width: 200px;
              height: 200px;
              border-radius: 40px;
              background-image: linear-gradient(-225deg, #9c09db 27%, #1c0fd7 100%);
              box-shadow: var(--alg-effect-shadow-l);
              content: '';
              opacity: 0.9;
              transform: rotate(45deg);

              @media (min-width: $w-large-l) {
                width: 280px;
                height: 280px;
              }

              @media (max-height: $h-medium-m) {
                width: 200px !important;
                height: 200px !important;
              }
            }

            &::before {
              position: absolute;
              z-index: 0;
              bottom: 20px;
              left: 50%;
              display: block;
              width: 200px;
              height: 200px;
              border-radius: 40px;
              background-image: linear-gradient(-225deg, #f2c314 0%, #fc3832 100%);
              content: '';
              opacity: 0.9;
              transform: rotate(65deg);

              @media (min-width: $w-large-l) {
                width: 280px;
                height: 280px;
              }

              @media (max-height: $h-medium-m) {
                width: 200px !important;
                height: 200px !important;
              }
            }

            & .app-logo {
              position: absolute;
              z-index: 2;
              top: 12.3%;
              left: 10%;
              display: flex;
              height: 22px;
              align-items: center;

              & .arrow {
                width: 0;
                height: 0;
                border-top: 5px solid var(--alg-color-icon-unselected);
                border-right: 4px solid var(--alg-color-transparent);
                border-left: 4px solid var(--alg-color-transparent);
                margin-left: 3.5px;
              }
            }

            & img {
              position: relative;
              z-index: 1;
              width: 300px;

              @media (min-width: $w-large-l) {
                width: 367px;
              }

              @media (max-height: $h-medium-m) {
                width: 300px;
              }
            }
          }

          @media (min-width: $w-large-s) {
            display: flex;
          }
        }

        @media (min-width: $w-large-s) {
          width: 50%;
        }
      }
    }

    .auth-footer {
      display: flex;
      width: 100%;
      justify-content: center;
      color: var(--alg-color-text-secondary);
      font-size: var(--alg-font-size-s);
    }

    .figure {
      position: absolute;
      z-index: 0;
      overflow: hidden;
      width: 160px;
      height: 160px;

      &::before {
        position: absolute;
        z-index: 0;
        bottom: 50px;
        left: 90px;
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 2px;
        background-color: #4798f8;
        content: '';
      }

      &::after {
        position: absolute;
        z-index: 0;
        bottom: 40px;
        left: 65px;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 2px;
        background-color: #4798f8;
        content: '';
      }

      &.figure-left {
        bottom: 0;
        left: 0;
      }

      &.figure-right {
        top: 0;
        right: 0;
      }

      .figure-contain {
        position: relative;
        top: -55px;
        right: -85px;
        width: inherit;
        height: inherit;
        border-radius: 20px;
        background-image: linear-gradient(-260deg, #32fbfc 0%, #3214f2 100%);
        opacity: 0.9;
        transform: rotate(45deg);

        .figure-contain-mini {
          position: absolute;
          bottom: -70px;
          left: 14px;
          display: flex;
          width: 34px;
          height: 34px;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          background-image: linear-gradient(-290deg, #32fbfc -10%, #3214f2 100%);
          transform: rotate(-75deg);

          & svg {
            transform: rotate(25deg);
          }
        }
      }
    }
  }
</style>
