<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  import {
    AlgIcon,
    AlgIconButton,
    AlgList,
    AlgListHeader,
    AlgListItem,
    AlgNotificationsPanel,
    AlgPopper,
  } from '@algorh/ui'

  import { useConfigs, useIsMobile } from '@/composables'

  import { useNotifications } from '@/sections/notifications/useNotifications'

  const router = useRouter()

  const { t } = useI18n()
  const { calendar } = useConfigs()

  const {
    markAsReadActivityChangeNotifications,
    markAsReadNotifications,
    unreadActivityChangeNotifications,
    unreadAllNotifications,
    unreadPlanningUpdateNotifications,
    unreadExchangeNotifications,
  } = useNotifications()

  const isMobile = useIsMobile()

  function handleNotificationsValidation(uuids: string[]) {
    markAsReadNotifications(uuids)
  }

  function handleGoToNotificationCenter(callback?: () => void) {
    if (callback) {
      callback()
    }

    router.push({ name: 'notifications' })
  }
</script>

<template>
  <div class="page-header-notifications">
    <AlgPopper placement="bottom-right">
      <template #reference="{toggle, isOpen}">
        <div class="page-header-notifications-icon">
          <AlgIconButton
            id="toggle-notifications"
            icon="notifications"
            :icon-color="isOpen ? 'var(--alg-color-icon-highlight)' : 'var(--alg-color-icon-secondary)'"
            :icon-badge="unreadAllNotifications.length > 0"
            icon-badge-state="danger"
            size="l"
            variant="transparent"
            @click="toggle"
          />
        </div>
      </template>
      <template #content="{close}">
        <AlgNotificationsPanel
          :fullscreen="isMobile"
          @go-to-notification-center="() => handleGoToNotificationCenter(close)"
          @close="close"
        >
          <AlgList>
            <template v-if="calendar?.data?.real_time_mode">
              <AlgListHeader>
                <template #title>
                  {{ t('notifications.Real time') }}
                </template>
                <template #append>
                  <AlgIconButton
                    id="mark-all-as-read-real-time"
                    icon="done-all"
                    :icon-color="unreadActivityChangeNotifications.length === 0 ?
                      'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-secondary)'"
                    variant="transparent"
                    size="m"
                    tooltip
                    tooltip-placement="left"
                    :label="t('notifications.Mark all as read')"
                    :disabled="unreadActivityChangeNotifications.length === 0"
                    @click="
                      () =>
                        markAsReadActivityChangeNotifications(
                          unreadActivityChangeNotifications.map(({ id }) => id)
                        )
                    "
                  />
                </template>
              </AlgListHeader>
              <template v-if="unreadActivityChangeNotifications.length">
                <AlgListItem
                  v-for="notification in unreadActivityChangeNotifications"
                  :key="notification.id"
                >
                  <template #prepend>
                    <AlgIcon
                      name="schedule"
                      size="m"
                      color="var(--alg-color-pink-100)"
                    />
                  </template>
                  <template #title>
                    {{ notification.content.subject }}
                  </template>
                  <template #description>
                    {{ notification.content.message }}
                  </template>
                  <template #append>
                    <AlgIconButton
                      :id="`mark-as-read-real-time-${notification.id}`"
                      icon="check"
                      icon-color="var(--alg-color-icon-secondary)"
                      variant="transparent"
                      size="m"
                      tooltip
                      tooltip-placement="left"
                      :label="t('notifications.Mark as read')"
                      @click="() => markAsReadActivityChangeNotifications([notification.id])"
                    />
                  </template>
                </AlgListItem>
              </template>
              <template v-else>
                <AlgListItem>
                  <template #description>
                    {{ t('account.No new notification') }}
                  </template>
                </AlgListItem>
              </template>
            </template>
            <AlgListHeader>
              <template #title>
                {{ t('notifications.Planning update') }}
              </template>
              <template #append>
                <AlgIconButton
                  id="mark-all-as-read-planning-update"
                  icon="done-all"
                  :icon-color="unreadPlanningUpdateNotifications.length === 0 ?
                    'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-secondary)'"
                  variant="transparent"
                  size="m"
                  tooltip
                  tooltip-placement="left"
                  :label="t('notifications.Mark all as read')"
                  :disabled="unreadPlanningUpdateNotifications.length === 0"
                  @click="
                    () =>
                      handleNotificationsValidation(
                        unreadPlanningUpdateNotifications.map(({ id }) => id)
                      )
                  "
                />
              </template>
            </AlgListHeader>
            <template v-if="unreadPlanningUpdateNotifications.length">
              <AlgListItem
                v-for="notification in unreadPlanningUpdateNotifications"
                :key="notification.id"
              >
                <template #prepend>
                  <AlgIcon
                    name="schedule"
                    size="m"
                    color="var(--alg-color-pink-100)"
                  />
                </template>
                <template #title>
                  {{ notification.content.subject }}
                </template>
                <template #description>
                  {{ notification.content.message }}
                </template>
                <template #append>
                  <AlgIconButton
                    :id="`mark-as-read-planning-update-${notification.id}`"
                    icon="check"
                    icon-color="var(--alg-color-icon-secondary)"
                    variant="transparent"
                    size="m"
                    tooltip
                    tooltip-placement="left"
                    :label="t('notifications.Mark as read')"
                    @click="() => handleNotificationsValidation([notification.id])"
                  />
                </template>
              </AlgListItem>
            </template>
            <template v-else>
              <AlgListItem>
                <template #description>
                  {{ t('account.No new notification') }}
                </template>
              </AlgListItem>
            </template>
            <template v-if="calendar?.data?.stock_exchanges">
              <AlgListHeader>
                <template #title>
                  {{ t('notifications.Exchange') }}
                </template>
                <template #append>
                  <AlgIconButton
                    id="mark-all-as-read-exchange"
                    icon="done-all"
                    :icon-color="unreadExchangeNotifications.length === 0 ?
                      'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-secondary)'"
                    variant="transparent"
                    size="m"
                    tooltip
                    tooltip-placement="left"
                    :label="t('notifications.Mark all as read')"
                    :disabled="unreadExchangeNotifications.length === 0"
                    @click="
                      () =>
                        handleNotificationsValidation(
                          unreadExchangeNotifications.map(({ id }) => id)
                        )
                    "
                  />
                </template>
              </AlgListHeader>
              <template v-if="unreadExchangeNotifications.length">
                <AlgListItem
                  v-for="notification in unreadExchangeNotifications"
                  :key="notification.id"
                >
                  <template #prepend>
                    <AlgIcon
                      name="business-center"
                      size="m"
                      color="var(--alg-color-cyan-100)"
                    />
                  </template>
                  <template #title>
                    {{ notification.content.subject }}
                  </template>
                  <template #description>
                    {{ notification.content.message }}
                  </template>
                  <template #append>
                    <AlgIconButton
                      :id="`mark-as-read-exchange-${notification.id}`"
                      icon="check"
                      icon-color="var(--alg-color-icon-secondary)"
                      variant="transparent"
                      size="m"
                      tooltip
                      tooltip-placement="left"
                      :label="t('notifications.Mark as read')"
                      @click="() => handleNotificationsValidation([notification.id])"
                    />
                  </template>
                </AlgListItem>
              </template>
              <template v-else>
                <AlgListItem>
                  <template #description>
                    {{ t('account.No new notification') }}
                  </template>
                </AlgListItem>
              </template>
            </template>
          </AlgList>
        </AlgNotificationsPanel>
      </template>
    </AlgPopper>
  </div>
</template>

<style lang="scss" scoped>
  .page-header-notifications {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .page-header-notifications-icon {
      position: relative;
    }
  }
</style>
