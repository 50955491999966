import type { StockExchangeConfig } from '@/interfaces'
import { fetchJson } from '@/utils/fetch'

import type { StockExchangeDashboardCounts } from '../types'

const BASE_URI = '/api/stock-exchanges'

const StockExchangesApiService = {
  getStockExchangesConfig() {
    return fetchJson<StockExchangeConfig>('GET', `${BASE_URI}/config`)
  },
  getStockExchangesDashboardCounts: () => {
    return fetchJson<StockExchangeDashboardCounts>('GET', `${BASE_URI}/dashboard`)
  },
}

export { StockExchangesApiService }
