import { RouteRecordRaw } from 'vue-router'

import { dayjs, DTF } from '@algorh/shared'

import { UserPermissions } from '@/core/enums'
import AppLayout from '@/layouts/app/App.vue'

const myPlanningRouter: RouteRecordRaw[] = [
  {
    name: 'calendar',
    path: 'calendar',
    redirect: {
      name: 'my-planning',
    },
  },
  {
    name: 'my-planning',
    path: 'my-planning/:date?',
    meta: {
      redirectable: true,
      weight: 100,
      permissions: [UserPermissions.ACCESS_TRAINING, UserPermissions.ACCESS_CALENDAR],
      layout: AppLayout,
      breadcrumb: 'calendar.My planning',
    },
    component: () => import('@/sections/my-planning/MyPlanningPage.vue'),
    beforeEnter: (to) => {
      if (to.params.date && dayjs(to.params.date as string, DTF.DATE).isValid()) {
        return true
      }
      return {
        name: 'my-planning',
        params: {
          // Calendrier s'ouvre tjs sur le lundi de la semaine en cours
          date: dayjs().startOf('week').format(DTF.DATE),
        },
      }
    },
  },
]

export { myPlanningRouter }
