<script setup lang="ts"  generic="T extends MenuItem<I>, I extends string|number">
  import { Nullable } from '@algorh/shared'

  export interface MenuItem<I = number> {
    content: string
    id: I
  }

  type Props = {
    readonly menuItems: T[]
    readonly highlighted: Nullable<I>
    readonly preventDuplicate?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    preventDuplicate: true,
  })

  const emit = defineEmits<{
    (e: 'onSelectItem', item: T): void
  }>()

  function onItemClick(item: T) {
    if (
      (props.highlighted !== item.id && props.preventDuplicate)
      || props.preventDuplicate === false
    ) {
      emit('onSelectItem', item)
    }
  }
</script>

<template>
  <nav
    class="sub-menu"
  >
    <ul class="sub-menu-container">
      <li
        v-for="(item, index) in props.menuItems"
        :key="index"
        class="item"
        :class="{
          active: highlighted === item.id
        }"
      >
        <button
          @click="onItemClick(item)"
          v-html="item.content"
        />
      </li>
    </ul>
    <span class="sub-menu-divider" />
  </nav>
</template>

<style lang="scss" scoped>
  .sub-menu {
    position: relative;
    display: flex;
    width: auto;

    &-container {
      display: flex;
      max-width: calc(100vw - 20px);
      flex-flow: row nowrap;
      gap: var(--alg-spacing-s);
      -webkit-overflow-scrolling: touch;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-border {
      position: relative;
      width: 100%;
      height: 3px;
      background-color: var(--alg-color-surface-highlight);
    }
  }

  li {
    min-width: 20px;
    height: 32px;
  }

  .item {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;

    button {
      padding: 0;
      color: var(--alg-color-text-secondary);
      font-size: var(--alg-font-size-l);
      font-weight: var(--alg-font-weight-bold);
      white-space: nowrap;
    }

    &.active {
      button {
        color: var(--alg-color-text-primary);
      }

      &::after {
        position: absolute;
        top: 29px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: var(--alg-color-surface-highlight);
        content: '';
      }
    }
  }
</style>
