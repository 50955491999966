import { RouteRecordRaw, RouterView } from 'vue-router'

import AuthLayout from '@/layouts/auth/AuthLayout.vue'

const authRouter: RouteRecordRaw[] = [
  {
    path: 'login',
    name: 'login',
    meta: {
      redirectable: true,
      unauthenticated: true,
      layout: AuthLayout,
    },
    component: () => import('@/sections/auth/Login.vue'),
  },
  {
    path: 'auth',
    name: 'auth',
    component: RouterView,
    meta: {
      unauthenticated: true,
      layout: AuthLayout,
    },
    children: [
      {
        path: 'missing-user',
        name: 'missing-user',
        meta: { redirectable: true },
        component: () => import('@/sections/auth/errors/MissingUser.vue'),
      },
      {
        path: 'external-failure',
        name: 'external-failure',
        meta: { redirectable: true },
        component: () => import('@/sections/auth/errors/ExternalFailure.vue'),
      },
    ],
  },
  {
    path: 'password',
    name: 'password',
    component: RouterView,
    meta: {
      unauthenticated: true,
      layout: AuthLayout,
    },
    children: [
      {
        name: 'password-forgot',
        path: 'forgot',
        component: () => import('@/sections/auth/ForgotPassword.vue'),
      },
      {
        name: 'password-init',
        path: 'init',
        component: () => import('@/sections/auth/ResetPassword.vue'),
      },
      {
        name: 'password-reset',
        path: 'reset',
        component: () => import('@/sections/auth/ResetPassword.vue'),
      },
    ],
  },
]

export { authRouter }
