import { nextTick } from 'vue'
import { createI18n, I18n, Locale } from 'vue-i18n'
import mergeWith from 'lodash.mergewith'

import { getLocale, I18nKeys, I18nRecord, messages } from '@algorh/shared'

export const i18n = createI18n({
  legacy: false, // Required to use the Composition API
  locale: getLocale(),
  fallbackLocale: 'en',
  messages,
  globalInjection: true,
})

export async function loadI18nOverrides(
  i18n: I18n<I18nRecord>,
  locale: Locale = 'en',
  tenant: string,
) {
  try {
    const i18nOverrides = await import(`../../../packages/shared/src/lang/${locale}/${tenant}/index.ts`)

    const messagesWithOverrides = mergeWith(
      messages[locale as I18nKeys],
      i18nOverrides.default,
    )

    i18n.global.setLocaleMessage(locale, messagesWithOverrides)

    return nextTick()
  } catch {
    //
  }
}
