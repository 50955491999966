import { UserPermissions } from '@/core/enums'
import AppLayout from '@/layouts/app/App.vue'

const statisticsRouter = [
  {
    path: 'statistics',
    name: 'statistics',
    component: () => import('./pages/Statistics.vue'),
    meta: {
      redirectable: true,
      weight: 80,
      permissions: [UserPermissions.ACCESS_STATISTICS, UserPermissions.ACCESS_SCHEDULING],
      layout: AppLayout,
      breadcrumb: 'statistics.Statistics',
    },
  },
]

export { statisticsRouter }
