<script setup lang="ts">
  import { computed } from 'vue'

  import {
    AlgIcon,
    AlgSpinner,
    AlgTooltip,
    IconBadgeState,
  } from '#/components'
  import { FloatingPlacement } from '#/composables'
  import type { IconButtonSize, IconButtonVariant, IconName } from '#/types'

  defineOptions({
    name: 'AlgIconButton',
  })

  const props = withDefaults(defineProps<Props>(), {
    variant: 'primary',
    size: 'm',
    disabled: false,
    inactive: false,
    loading: false,
    iconBadge: false,
    tooltip: false,
    tooltipPlacement: 'top',
  })

  const emit = defineEmits<{
    (e: 'click', p: MouseEvent): void
    (e: 'focus', p: FocusEvent): void
    (e: 'blur', p: FocusEvent): void
  }>()

  interface Props {
    id: string
    label?: string
    variant?: IconButtonVariant
    size?: IconButtonSize
    disabled?: boolean
    inactive?: boolean
    loading?: boolean
    icon: IconName
    iconBadge?: boolean
    iconBadgeState?: IconBadgeState
    tooltip?: boolean
    tooltipPlacement?: FloatingPlacement
    iconColor?: string
    iconRotate?: number
  }

  const computedClasses = computed(() => [
    'icon-button',
    `variant-${props.variant}`,
    `size-${props.size}`,
    {
      disabled: props.disabled,
      inactive: props.inactive,
      loading: props.loading,
    },
  ])

  const computedIconSize = computed(() => {
    switch (props.size) {
    case 's':
      return 'xs'
    case 'm':
      return 's'
    case 'l':
      return 'm'
    default:
      return 's'
    }
  })
</script>

<template>
  <AlgTooltip
    v-if="props.tooltip"
    :id="`${props.id}`"
    :placement="props.tooltipPlacement"
  >
    <template #reference>
      <button
        role="button"
        :class="computedClasses"
        :aria-label="props.label"
        :aria-disabled="props.inactive || props.disabled || props.loading"
        :disabled="props.inactive || props.disabled || props.loading"
        @click="(p) => emit('click', p)"
        @focus="(p) => emit('focus', p)"
        @blur="(p) => emit('blur', p)"
      >
        <AlgIcon
          v-if="!props.loading"
          :name="props.icon"
          :color="props.iconColor"
          :rotate="props.iconRotate"
          :size="computedIconSize"
          :badge="props.iconBadge"
          :badge-state="props.iconBadgeState"
        />
        <AlgSpinner
          v-else-if="props.loading"
          :size="computedIconSize"
        />
      </button>
    </template>
    <template #content>
      {{ label }}
    </template>
  </AlgTooltip>
  <button
    v-else
    role="button"
    :class="computedClasses"
    :aria-label="props.label"
    :aria-disabled="props.inactive || props.disabled || props.loading"
    :disabled="props.inactive || props.disabled || props.loading"
    @click="(p) => emit('click', p)"
    @focus="(p) => emit('focus', p)"
    @blur="(p) => emit('blur', p)"
  >
    <AlgIcon
      v-if="!props.loading"
      :name="props.icon"
      :color="props.iconColor"
      :rotate="props.iconRotate"
      :size="computedIconSize"
      :badge="props.iconBadge"
      :badge-state="props.iconBadgeState"
    />
    <AlgSpinner
      v-else-if="props.loading"
      :size="computedIconSize"
    />
  </button>
</template>

<style lang="scss" scoped>
  .icon-button {
    position: relative;
    display: inline-flex;
    overflow: hidden;
    width: auto;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 1px solid var(--alg-color-transparent);
    border-radius: var(--alg-effect-radius-m);
    margin: 0;
    appearance: none;
    background-color: var(--alg-color-transparent);
    color: inherit;
    cursor: pointer;
    font: inherit;
    font-weight: var(--alg-font-weight-bold);
    gap: var(--alg-spacing-s);
    text-overflow: ellipsis;
    transition-duration: var(--alg-transition-colors-duration);
    transition-property: var(--alg-transition-colors-property);
    transition-timing-function: var(--alg-transition-colors-timing-function);
    white-space: nowrap;

    &.disabled {
      cursor: not-allowed;
    }

    &.inactive,
    &.loading {
      cursor: default;
    }

    &.size-s {
      width: 1.875rem;
      height: 1.875rem;
      font-size: var(--alg-font-size-s);
      font-weight: var(--alg-font-weight-regular);
      line-height: 1.875rem;
    }

    &.size-m {
      width: 2.5rem;
      height: 2.5rem;
      font-size: var(--alg-font-size-m);
      line-height: 2.5rem;
    }

    &.size-l {
      width: 3.125rem;
      height: 3.125rem;
      font-size: var(--alg-font-size-m);
      line-height: 3.125rem;
    }

    &.variant-primary {
      border-color: var(--alg-color-button-primary);
      background-color: var(--alg-color-button-primary);
      color: var(--alg-color-text-on-color);

      &:focus-visible,
      &:hover {
        border-color: var(--alg-color-button-primary-hover);
        background-color: var(--alg-color-button-primary-hover);
      }

      &:disabled {
        border-color: var(--alg-color-button-primary-disabled);
        background-color: var(--alg-color-button-primary-disabled);
      }
    }

    &.variant-secondary {
      border-color: var(--alg-color-surface-border);
      background-color: var(--alg-color-button-secondary);
      color: var(--alg-color-text-primary);

      &:focus-visible,
      &:hover {
        border-color: var(--alg-color-text-light);
      }

      &:disabled {
        color: var(--alg-color-button-secondary-disabled);

        &:hover {
          border-color: var(--alg-color-surface-border);
        }
      }
    }

    &.variant-transparent {
      width: auto;
      height: auto;
      border-radius: 0;
      color: var(--alg-color-button-primary);

      &.disabled {
        color: var(--alg-color-button-secondary-disabled);
      }
    }
  }
</style>
