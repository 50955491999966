import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
type Hexa = `#${number | string}`

type Theme = Record<string, string | Hexa>

export const useAppStore = defineStore('app', () => {
  // Blade State
  const appLogo = ref(window.appLogo)
  const appTenant = ref(window.appTenant)
  const appMockup = ref(window.appMockup)
  const appName = ref(window.appName)
  const userCompaniesEnabled = ref(window.userCompaniesEnabled)

  // Refs
  const theme = ref<Theme>({
    // Canvas does not support CSS variables
    // Schedule mode colors
    PREDEFINED: '#008ffb',
    FREESTYLE: '#005BBB', // Blue 100
    USER_DEFINED: '#A790FF', // Purple 60
    UNDEFINED: '#FCB9DD', // Pink 40
    // Satisfaction colors
    SATISFIED_SLOTS: '#2ECC71', // Green 80
    NOT_SATISFIED_SLOTS: '#FF7575', // Red 80
  })

  const leaveBalanceUnit = computed(() => {
    switch (appTenant.value) {
      case 'edf-ds2c':
      case 'edf-maff':
      case 'edf-sconso':
      case 'edf-solidarite':
        return 'hour'
      default:
        return 'day'
    }
  })

  return {
    appLogo,
    appTenant,
    appMockup,
    appName,
    userCompaniesEnabled,
    theme,
    leaveBalanceUnit,
  }
})
