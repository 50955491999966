import { fetchJson } from '@/utils/fetch'

import { OperationalSubdivision, OperationalSubdivisionNamesTree } from '../types/OperationalSubdivision'
import { ProjectActivity } from '../types/Project'
import { Role } from '../types/User'

import { CommonConfig } from './CommonConfig.type'

const BASE_URI = '/api/common'

const CommonApiService = {
  getCommonConfig() {
    return fetchJson<CommonConfig>('GET', `${BASE_URI}/config`)
  },
  getProjectActivities() {
    return fetchJson<ProjectActivity[]>('GET', `${BASE_URI}/project-activities`)
  },
  getSubdivisions() {
    return fetchJson<OperationalSubdivision[]>('GET', `${BASE_URI}/operational-subdivisions`)
  },
  getSubdivisionsNamesTree() {
    return fetchJson<OperationalSubdivisionNamesTree[]>('GET', `${BASE_URI}/operational-subdivisions/tree`)
  },
  getEffectiveSubdivisions() {
    return fetchJson<OperationalSubdivision[]>('GET', `${BASE_URI}/operational-subdivisions/effective`)
  },
  getRoles() {
    return fetchJson<Role[]>('GET', `${BASE_URI}/roles`)
  },
}

export { CommonApiService }
