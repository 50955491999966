import { ProjectActivityLite } from '@algorh/shared'

import { SettingsConfig } from '@/interfaces'
import { fetchJson } from '@/utils/fetch'

import { CustomerAdviserTypePreview } from '../types/CustomerAdviser'
import { Company } from '../types/User'

const BASE_URI = '/api/settings'

const SettingsApiService = {
  getSettingsConfig() {
    return fetchJson<SettingsConfig>(
      'GET', `${BASE_URI}/config`,
    )
  },
  getProjectActivities() {
    return fetchJson<ProjectActivityLite[]>(
      'GET', `${BASE_URI}/project-activities`,
    )
  },
  getCustomerAdviserTypesPreviews() {
    return fetchJson<CustomerAdviserTypePreview[]>(
      'GET', `${BASE_URI}/customer-adviser-types/previews`,
    )
  },
  getCompanies() {
    return fetchJson<Company[]>(
      'GET', `${BASE_URI}/companies`,
    )
  },
}

export { SettingsApiService }
