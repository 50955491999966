<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { Nullable, sanitizeString } from '@algorh/shared'

  import { InputSize, InputVariant } from '..'

  import { AlgErrors, AlgIcon, AlgLabel } from '#/components'
  import { IconName } from '#/types'

  type Props = {
    readonly type?: 'text' | 'email'
    readonly id: string
    readonly modelValue: Nullable<string>
    readonly label?: string
    readonly sublabel?: string
    readonly placeholder?: string
    readonly autocomplete?: string
    readonly autocapitalize?: string
    readonly spellcheck?: boolean
    readonly size?: InputSize
    readonly inline?: boolean
    readonly required?: boolean
    readonly disabled?: boolean
    readonly readonly?: boolean
    readonly maxlength?: number
    readonly errored?: boolean
    readonly errors?: string[]
    readonly prefixIcon?: IconName
    readonly suffixIcon?: IconName
    readonly variant?: InputVariant
  }

  defineOptions({
    name: 'AlgTextInput',
  })

  const props = withDefaults(defineProps<Props>(), {
    type: 'text',
    autocapitalize: 'off',
    autocomplete: 'off',
    spellcheck: false,
    size: 'm',
    inline: false,
    required: false,
    disabled: false,
    readonly: false,
    errored: false,
    variant: 'primary',
  })

  const emit = defineEmits<{
    (e: 'update:modelValue', value: Nullable<string>): void
  }>()

  const { t } = useI18n()

  // Computed
  const hasErrors = computed(() => props.errored || (props.errors && props.errors.length > 0))

  // Methods
  async function handleInput(event: Event) {
    const { value } = event.target as HTMLInputElement

    emit('update:modelValue', value)
  }
</script>

<template>
  <div
    class="field-wrapper"
    :class="{ inline: props.inline}"
  >
    <AlgLabel
      v-if="props.label"
      :label="props.label"
      :sublabel="props.sublabel"
      :html-for="props.id"
      :inline="props.inline"
      :input-size="props.size"
      :required="props.required"
      :errored="hasErrors"
    />
    <div class="field-content">
      <div class="input-wrapper">
        <input
          :id="props.id"
          :type="props.type"
          class="input"
          :class="[
            `size-${props.size}`,
            `variant-${props.variant}`,
            {
              errored: hasErrors,
              'has-prefix': props.prefixIcon,
              'has-suffix': props.suffixIcon,
              'has-counter': props.maxlength,
            }
          ]"
          :name="props.id"
          :placeholder="props.placeholder"
          :autocomplete="props.autocomplete"
          :autocapitalize="props.autocapitalize"
          :spellcheck="props.spellcheck"
          :required="props.required"
          :disabled="props.disabled"
          :readonly="props.readonly"
          :maxlength="props.maxlength"
          :value="props.modelValue"
          @input="handleInput"
        >
        <span
          v-if="props.prefixIcon"
          class="input-prefix"
        >
          <AlgIcon
            :name="props.prefixIcon"
            :color="
              props.disabled ? 'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-secondary)'
            "
            size="s"
          />
        </span>
        <span
          v-if="props.modelValue && props.maxlength"
          class="input-counter"
          :class="{
            'has-suffix': props.suffixIcon,
          }"
        >
          {{
            t(
              'common.{0} / {1}',
              [
                sanitizeString(props.modelValue).length,
                props.maxlength,
              ],
            )
          }}
        </span>
        <span
          v-if="props.suffixIcon"
          class="input-suffix"
        >
          <AlgIcon
            :name="props.suffixIcon"
            :color="
              props.disabled ? 'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-secondary)'
            "
            size="s"
          />
        </span>
      </div>
      <AlgErrors
        v-if="props.errors && props.errors.length"
        :errors="props.errors"
      />
    </div>
  </div>
</template>

<style lang="scss" src="../index.scss" scoped />
