<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AlgIllustration, AlgModal } from '@algorh/ui'

  const { t } = useI18n()

  const handleConfirm = () => {
    localStorage.clear()
    window.location.reload()
  }
</script>

<template>
  <AlgModal
    name="app-update"
    size="m"
    :allow-close="false"
    :allow-cancel="false"
    :confirm-button-text="t('common.Continue')"
    @confirm="handleConfirm"
  >
    <template #title>
      {{ t('common.New version available') }}
    </template>
    <template #content>
      <div class="content-wrapper">
        <AlgIllustration
          name="deploying"
          :size="148"
        />
        <p class="subtitle">
          {{ t('common.A new version of the application is available') }}
        </p>
        <p>{{ t('common.Please update to continue using the application') }}</p>
      </div>
    </template>
  </AlgModal>
</template>

<style lang="scss" scoped>
  @import './AlertModal';
</style>
