<script setup lang="ts">
  import { Nullable } from '@algorh/shared'
  import { AlgAvatar } from '@algorh/ui'

  import { User } from '@/core/types/Me'

  type Props = {
    readonly user?: Nullable<User>
  }

  const props = defineProps<Props>()
</script>

<template>
  <div class="user-infos">
    <AlgAvatar
      class="avatar"
      size="s"
    />
    <span
      v-if="props.user"
      class="details"
    >
      <span class="name">
        {{ props.user.full_name }}
      </span>
      <span
        v-if="props.user.roles.length"
        class="category"
      >
        <span
          v-for="(item, key) in props.user.roles"
          :key="item.id"
        >
          {{ item.label }}<span v-if="props.user.roles.length > key + 1">, </span>
        </span>
      </span>
    </span>
  </div>
</template>

<style lang="scss" scoped>
  .user-infos {
    display: flex;
    overflow: hidden;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: center;
    gap: var(--alg-spacing-s);

    .avatar {
      flex: 0 0 auto;
    }

    .details {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .name,
      .category {
        display: block;
        overflow: hidden;
        width: 100%;
        font-size: var(--alg-font-size-m);
        line-height: var(--alg-font-line-height);
        text-overflow: ellipsis;
        white-space: nowrap
      }

      .name {
        color: var(--alg-color-text-primary);
        font-weight: var(--alg-font-weight-bold);
      }

      .category {
        color: var(--alg-color-text-secondary);
        font-weight: var(--alg-font-weight-regular);
      }
    }
  }
</style>
