import { RouteRecordRaw, RouterView } from 'vue-router'

import { UserPermissions } from '@/core/enums'
import AppLayout from '@/layouts/app/App.vue'

const projectsRouter: RouteRecordRaw[] = [
  {
    name: 'projects',
    path: 'projects',
    redirect: { name: 'project-list' },
    meta: {
      permissions: [UserPermissions.MANAGE_PROJECTS],
      layout: AppLayout,
      breadcrumb: 'projects.Projects',
    },
    component: RouterView,
    children: [
      {
        name: 'project-list',
        path: '',
        component: () => import('./pages/Projects.vue'),
      },
      {
        name: 'project',
        path: ':projectId',
        redirect: { name: 'project-activities' },
        meta: {
          permissions: [UserPermissions.MANAGE_PROJECTS],
          layout: AppLayout,
          breadcrumb: ':projectId',
        },
        component: () => import('./pages/Project.vue'),
        children: [
          {
            name: 'project-activities',
            path: 'activities',
            meta: {
              permissions: [UserPermissions.MANAGE_PROJECTS],
              breadcrumb: 'activities.Activities',
            },
            component: () => import('./pages/activities/ProjectActivities.vue'),
          },
          {
            name: 'project-rules',
            path: 'rules',
            meta: {
              breadcrumb: 'rules.Collective rules',
            },
            component: () => import('./pages/rules-ambitions/ProjectRules.vue'),
          },
          {
            name: 'project-ambitions',
            path: 'ambitions',
            meta: {
              breadcrumb: 'ambitions.Ambitions',
            },
            component: () => import('./pages/rules-ambitions/ProjectAmbitions.vue'),
          },
        ],
      },
    ],
  },
]

export { projectsRouter }
