<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { Nullable } from '@algorh/shared'

  import { InputSize, InputVariant } from '..'

  import { AlgErrors, AlgIcon, AlgLabel } from '#/components'

  type Props = {
    readonly id: string
    readonly modelValue: Nullable<string>
    readonly label?: string
    readonly sublabel?: string
    readonly placeholder?: string
    readonly size?: InputSize
    readonly inline?: boolean
    readonly required?: boolean
    readonly disabled?: boolean
    readonly errored?: boolean
    readonly errors?: string[]
    readonly variant?: InputVariant
  }

  defineOptions({
    name: 'AlgPasswordInput',
  })

  const props = withDefaults(defineProps<Props>(), {
    size: 'm',
    inline: false,
    required: false,
    disabled: false,
    errored: false,
    variant: 'primary',
  })

  const emit = defineEmits<{
    (e: 'update:modelValue', value: Nullable<string>): void
  }>()

  // Composables
  const { t } = useI18n()

  // Refs
  const showPassword = ref(false)

  // Computed
  const hasErrors = computed(() => props.errored || (props.errors && props.errors.length > 0))

  // Methods
  function toggle() {
    showPassword.value = !showPassword.value
  }

  async function handleInput(event: Event) {
    const { value } = event.target as HTMLInputElement

    emit('update:modelValue', value)
  }
</script>

<template>
  <div
    class="field-wrapper"
    :class="{ inline: props.inline }"
  >
    <AlgLabel
      v-if="props.label"
      :label="props.label"
      :sublabel="props.sublabel"
      :html-for="props.id"
      :inline="props.inline"
      :input-size="props.size"
      :required="props.required"
      :errored="hasErrors"
    />
    <div class="field-content">
      <div class="input-wrapper">
        <input
          :id="props.id"
          :type="showPassword ? 'text' : 'password'"
          class="input has-suffix"
          :class="[
            `size-${props.size}`,
            `variant-${props.variant}`,
            {
              errored: hasErrors
            }
          ]"
          :name="props.id"
          :placeholder="props.placeholder"
          :required="props.required"
          :disabled="props.disabled"
          :value="props.modelValue"
          @input="handleInput"
        >
        <span class="input-suffix">
          <button
            type="button"
            :title="showPassword ? t('common.Hide password') : t('common.Show password')"
            :disabled="props.disabled"
            @click="toggle"
          >
            <AlgIcon
              :name="showPassword ? 'visibility-off' : 'visibility'"
              :color="
                props.disabled ? 'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-primary)'
              "
              size="s"
            />
          </button>
        </span>
      </div>
      <AlgErrors
        v-if="props.errors && props.errors.length"
        :errors="props.errors"
      />
    </div>
  </div>
</template>

<style lang="scss" src="../index.scss" scoped />
